<template src="./productDetailsNoReview.html"></template>

<script>
import Vue from 'vue';
// import axios from 'axios';
import productCard from '../productCard/productCard';
import header from '../header/header';
import { Carousel, Slide } from 'vue-carousel';
import ReadMore from 'vue-read-more';

Vue.use(ReadMore);

export default {
  name: 'productSubCategory',
  components: {
    productCard,
    Carousel,
    Slide,
    recommendationHeader: header,
  },
  data() {
    return {
      quotes: null,
      product: {
        description: `Magic Eyeliner Perfector merupakan kolaborasi SASC dengan Titan Tyra ini adalah Eyeliner yang tahan air, berpigmen tinggi, sikat atau brush yang tipis membantu Anda mendapatkan garis halus. Berfungsi di untuk mengisi bulu mata yang jarang, Cepat kering dan Mudah digunakan. eyeliner berpigmen tinggi di superblack dengan ujung yang sangat tajam. tidak luntur dan tidak transfer ke bawah mata. SASC Magic Perfector Eyeliner memiliki aplikator felt tip dengan warna hitam yang intens. Klaimnya produk ini yaitu liquid eyliner ini mudah diaplikasikan, waterproof, dan cepat kering tanpa perlu menunggu lama.

                            <strong>CARA PENGGUNAAN :</strong>
                            Dengan hati-hati gambarlah tiga garis tipis di sepanjang mata Anda, lalu hubungkan dengan mereka. Untuk membuat sayap, gambarlah titik kecil di dekat sudut terluar mata Anda. Kemudian tarik garis dari titik langsung ke sudut bagian dalam mata Anda. Voilà!

                            <strong>KOMPOSISI :</strong>
                            Air, CI77266, Amonium Acrylates, Copolymer, Laureth-21, Propanediol, PEG-40 Oil Castor dihidrogenasi, Phenoxyethanol, Caprylyl Glygol, Sodium Dehydroacetate`,
      },
    };
  },
  computed: {},
  created() {
    /*axios
        .get('https://soco-loyalty-feature-api.sociolabs.io/beauty-quotes?limit=1&sort=-created_at')
        .then(res => {
        const data = res.data.data;
        // console.log(data);
        this.quotes = data;
      });*/
  },
};
</script>

<style lang="scss" scoped>
@import 'productDetailsNoReview';

.VueCarousel {
  .VueCarousel-navigation-button {
    background-color: #ffffff;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    outline: none;
    box-shadow: 0.5px 2px 4px 0 #c9c9c9;
    display: flex;
  }

  .VueCarousel-pagination {
    width: 100%;
    text-align: center;
    padding-top: 19px;
  }

  .VueCarousel-navigation--disabled {
    opacity: 1;
  }

  .VueCarousel-navigation-prev,
  .VueCarousel-navigation-next {
    left: 15px;
    &:before {
      content: '';
      display: inline-block;
      background: transparent url('~static/img/recommendation-icon-arrow-left.svg') no-repeat center;
      width: 12px;
      height: 21px;
      background-size: cover;
    }
  }

  .VueCarousel-navigation-next {
    left: auto;
    right: 15px;
    &:before {
      background-image: url('~static/img/recommendation-icon-arrow-right.svg');
    }
  }

  .VueCarousel-dot:focus {
    outline: none;
  }
}

@media (max-width: 900px) {
}
</style>
